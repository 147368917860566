import React, { Fragment } from 'react';
import tw, { styled } from 'twin.macro';
import AssumptionCard from './components/AssumptionCard';
import { graphql, useStaticQuery } from 'gatsby';
import { GraphAssumptionsGraphQLType } from './types';
import { useIntl } from 'react-intl';

type EPSAchievementProps = {};

const Container = tw.div`

`;
const ComponentHeading = tw.h2`
text-[24px] !font-[500] leading-[36px] lg:text-[36px] lg:leading-[54px] text-center text-base_text mb-2
`;
const SubHeading = tw.p`
text-N700 text-center mb-12 text-[14px] lg:text-[20px]
`;
const ImageContainer = tw.div`
flex my-12 justify-center
`;
const CardsWrapper = tw.div`
flex flex-col lg:flex-row gap-[10px] lg:gap-12 items-center justify-center lg:items-stretch
`;
const ExtraRichText = styled.div`
  ${tw`py-1 px-4 bg-light_yellow text-O500 rounded-[33px]`}
  p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }
  strong {
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
  }
  @media (max-width: 1024px) {
    strong {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
const LinkText = tw.div`
text-N400 text-[24px] lg:text-[48px] font-[500] leading-[115%] self-center
`;

const EPSAchievement = (props: EPSAchievementProps) => {
  const { formatMessage } = useIntl();
  const data = useStaticQuery(graphql`
    query {
      allStrapiEpsAchievement {
        nodes {
          heading
          subHeading
          graphImage {
            alternativeText
            url
          }
          graphAssumptions {
            id
            label
            description
            figure
            FontAwesomeIcon
            colorHexCode
            extraText {
              data {
                extraText
              }
            }
          }
        }
      }
    }
  `);
  const {
    heading,
    subHeading,
    graphImage: {
      alternativeText: graphImageAlternativeText,
      url: graphImageUrl,
    },
    graphAssumptions,
  } = data.allStrapiEpsAchievement.nodes[0];

  return (
    <Container>
      <ComponentHeading>{heading}</ComponentHeading>
      <SubHeading>{subHeading}</SubHeading>

      <ImageContainer>
        <img
          src={graphImageUrl}
          alt={
            !!graphImageAlternativeText ? graphImageAlternativeText : 'graph'
          }
          className="object-contain max-h-[207px] md:max-h-[350px] lg:max-h-[442px]"
        />
      </ImageContainer>

      <CardsWrapper>
        {(graphAssumptions as GraphAssumptionsGraphQLType[]).map(
          (
            {
              id,
              label,
              description,
              figure,
              extraText,
              colorHexCode,
              FontAwesomeIcon,
            },
            index,
          ) => {
            const extraRichText = extraText.data.extraText;
            const isLastAssumption = index === graphAssumptions.length - 1;
            return (
              <Fragment key={id}>
                <AssumptionCard
                  title={label}
                  description={description}
                  value={figure}
                  color={colorHexCode}
                  icon={`fa-regular fa-${FontAwesomeIcon}`}
                  extra={
                    extraRichText ? (
                      <ExtraRichText
                        dangerouslySetInnerHTML={{ __html: extraRichText }}
                      />
                    ) : undefined
                  }
                />
                {!isLastAssumption && (
                  <LinkText>{formatMessage({ id: 'vs' })}</LinkText>
                )}
              </Fragment>
            );
          },
        )}
      </CardsWrapper>
    </Container>
  );
};

export default EPSAchievement;
