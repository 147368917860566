import * as React from 'react';

import { PREFIX_CANONICAL_URL } from 'components/constant';

import HFSLayout from 'components/HFS/HFSLayout';
import { graphql } from 'gatsby';
import { PageSEOType } from 'components/types';
import SEOGraphQL from 'components/SEOGraphQL';
import ProductEPS from 'components/pages/HFS/productEPS';

const pageUrl = `${PREFIX_CANONICAL_URL}/financial/early-payment-system`;

const EPSPage = () => {
  return (
    <HFSLayout headerStyle="transparent">
      <ProductEPS pageUrl={pageUrl} />
    </HFSLayout>
  );
};
export default EPSPage;

export const Head = ({
  data,
}: {
  data: {
    allStrapiHfsProductPage: {
      nodes: PageSEOType[];
    };
  };
}) => {
  return (
    <SEOGraphQL
      data={data.allStrapiHfsProductPage.nodes?.[0]}
      canonicalURL={pageUrl}
    />
  );
};

export const query = graphql`
  query {
    allStrapiHfsProductPage(filter: { ONLY_FOR_DEV_pageId: { eq: "EPS" } }) {
      nodes {
        ONLY_FOR_DEV_pageId
        seo {
          preventIndexing
        }
        SEO {
          metaDescription
          metaTitle
          metaSocial {
            title
            description
            image {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
