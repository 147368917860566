import React from 'react';
import tw from 'twin.macro';

type AssumptionCardProps = {
  title: string;
  description: string;
  value: string;
  extra?: React.ReactElement;
  color: string;
  icon: string;
};

const ComponentWrapper = tw.div`
w-full lg:w-[496px] text-center flex flex-col items-center border border-[#E1E3EA] rounded-[10px] shadow-[0px_3px_8px_0px_rgba(41,45,55,0.15)]
`;
const Title = tw.h3`
w-full py-2 px-4 rounded-t-[10px] mb-0 text-[14px] lg:text-md font-[500] leading-6
`;
const ContentWrapper = tw.div`
w-full h-full p-4  lg:pt-6 lg:pb-8 lg:px-4 flex flex-col
`;
const Description = tw.p`
text-[14px] lg:text-md text-subtext leading-6 mb-4
`;
const FigureAndIcon = tw.div`
flex flex-row items-center justify-center gap-3 font-[500] text-[32px] lg:text-[48px]
`;

const AssumptionCard = ({
  title,
  description,
  value,
  extra,
  color,
  icon,
}: AssumptionCardProps) => {
  return (
    <ComponentWrapper>
      <Title style={{ backgroundColor: color }}>{title}</Title>
      <ContentWrapper>
        <Description>{description}</Description>
        <div className="flex-1 flex flex-col items-center justify-center gap-4">
          <FigureAndIcon>
            <i style={{ color }} className={icon} />
            <span className="block text-hubble_blue">{value}</span>
          </FigureAndIcon>
          {extra}
        </div>
      </ContentWrapper>
    </ComponentWrapper>
  );
};

export default AssumptionCard;
